import clsx from 'clsx';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Input from '../../components/Input';
import Typography from '../../components/Typography';
import { getResponseError } from '../../helpers/hydra';
import { createRedeem } from '../../services/api/redeems';
import styles from './style.module.scss';

interface ICouponForm {
  code: string;
}

const Coupon = () => {
  const { code } = useParams();

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<ICouponForm>({
    defaultValues: { code },
  });

  const {
    onChange: onChangeCodeInput,
    onBlur: onBlurCodeInput,
    name: codeInputName,
    ref: codeInputRef,
  } = register('code', {
    required: {
      value: true,
      message: 'Необходимо ввести код купона',
    },
  });

  const { errors } = useFormState({
    control,
  });

  const applyCoupon = async (code: string) => {
    return createRedeem({ code });
  };

  const onSubmitForm: SubmitHandler<ICouponForm> = async (data) => {
    try {
      await applyCoupon(data.code);

      reset({ code: '' });
      navigate('/coupon', { replace: true });
    } catch (error: any) {
      const errorText = getResponseError(error);

      if (errorText) {
        setError('code', { message: errorText, type: 'required' });
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card type="form" className={clsx(styles.card)}>
        {isSubmitSuccessful && (
          <div className={styles.successWrapper}>
            <Typography className={styles.successfulMessage}>
              Купон успешно применен!
            </Typography>
            <Button
              className={styles.btn}
              buttonType="primary"
              size="S"
              type="submit"
              to="/rating"
            >
              К рейтингу
            </Button>
          </div>
        )}
        {!isSubmitSuccessful && (
          <form className={styles.form} onSubmit={handleSubmit(onSubmitForm)}>
            <Typography>Введите код подарочного купона</Typography>
            <div className={styles.inputs}>
              <Input
                className={styles.value}
                placeholder={`CREATIVE${new Date().getFullYear()}`}
                onBlur={onBlurCodeInput}
                onChange={onChangeCodeInput}
                invalid={!!errors.code}
                name={codeInputName}
                ref={codeInputRef}
                autoComplete="off"
              />
            </div>

            {!!errors.code && (
              <div className={styles.error}>
                {errors.code.type === 'required' && (
                  <p>{errors.code.message}</p>
                )}
              </div>
            )}

            <Button
              className={styles.btn}
              buttonType="primary"
              size="S"
              type="submit"
              disabled={isSubmitting}
            >
              Активировать
            </Button>
          </form>
        )}
      </Card>
    </div>
  );
};

export default Coupon;
