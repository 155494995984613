import clsx from "clsx";
import React, { FC, memo } from "react";

import ITagProps from "./Tag.interface";

import styles from "./style.module.scss";

const Tag: FC<ITagProps> = ({
  content,
  onClick,
  className,
  type = "primary",
}) => {
  return (
    <span
      onClick={onClick}
      className={clsx(styles.tag, className, {
        [styles.primary]: type === "primary",
        [styles.secondary]: type === "secondary",
      })}>
      {content}
    </span>
  );
};

export default memo(Tag);
