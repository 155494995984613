import clsx from "clsx";
import { forwardRef, useState, FocusEvent } from "react";

import IInputProps from "./Input.interface";

import styles from "./style.module.scss";

const Input = forwardRef<any, IInputProps>(
  (
    {
      size = "S",
      onClick,
      className,
      suffixIcon,
      prefixIcon,
      onFocus,
      onBlur,
      invalid,
      ...restProps
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
      setFocused(true);
      onFocus && onFocus(event);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
      setFocused(false);
      onBlur && onBlur(event);
    };

    return (
      <div
        className={clsx(styles.container, className, {
          [styles[`size-${size}`]]: size,
          [styles["focused"]]: focused,
          [styles["invalid"]]: invalid,
        })}>
        {prefixIcon}
        <input
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={styles.input}
          {...restProps}
        />
        {suffixIcon}
      </div>
    );
  }
);

export default Input;
