import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";

import IButtonProps from "./Button.interface";

import styles from "./style.module.scss";

const Button: FC<IButtonProps> = ({
  className,
  children,
  onClick,
  size,
  icon,
  buttonType,
  disabled,
  to,
  ...rest
}) => {
  const classes = clsx(styles.button, className, {
    [styles.disabled]: disabled,
    [styles[`size-${size}`]]: size,
    [styles[`type-${buttonType}`]]: buttonType,
  });

  if (to) {
    return <Link className={classes} to={to}>
    {children}
    </Link>
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      disabled={!!disabled}
      {...rest}>
      {children}

      {icon && <img src={icon} alt="test" />}
    </button>
  );
};

export default Button;
