import React from 'react';
import { AppRouter } from './routes';
import { KeycloakProvider } from './services/keycloak';
import { QueryProvider } from './services/query';

import './assets/fonts/RFDewi-Bold.woff2';
import './assets/fonts/RFDewi-Regular.woff2';
import './assets/fonts/RFDewi-Semibold.woff2';

import './style/.index.scss';
import { UserProvider } from './services/user';

const App = () => {
  return (
    <QueryProvider>
      <KeycloakProvider>
        <React.StrictMode>
          <UserProvider>
            <AppRouter />
          </UserProvider>
        </React.StrictMode>
      </KeycloakProvider>
    </QueryProvider>
  );
};

export default App;
