import { useQueryClient } from '@tanstack/react-query';
import { TabPane } from 'rc-tabs';
import { useContext, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as StepBackIcon } from '../../../assets/svg/icons/16/step-back.svg';
import Loader from '../../../components/Loader';
import Tabs from '../../../components/Tabs';
import Tag from '../../../components/Tag';
import UserTable from '../../../components/UserTable';
import { UserContext } from '../../../contexts/user';
import { getAvatar } from '../../../helpers/avatar';
import { getId } from '../../../helpers/hydra';
import useGiftAccount from '../../../hooks/api/useGiftAccount';
import useMainAccount from '../../../hooks/api/useMainAccount';
import { GiveCard, GiveCardMobile, KarmaCard } from '../../../modules/Cards';
import SuccessPopup from '../../../modules/SuccessPopup';
import { createTransaction } from '../../../services/api/transactions';
import { useMemberPageData } from './hooks';
import styles from './style.module.scss';

const MemberProfile = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { '@id': currentUserId } = useContext(UserContext)!;

  const {
    memberData,
    memberError,
    isMemberLoading,
    allMemberTransactions,
    memberTopups,
    memberWithdrawals,
  } = useMemberPageData(id!);

  const { isAccountLoading, accountData, accountError } = useMainAccount(id!);
  const {
    isAccountLoading: isGiftAccountLoading,
    accountData: giftAccountData,
    accountError: giftAccountError,
  } = useGiftAccount(getId(currentUserId));

  if (isMemberLoading) {
    return <Loader />;
  }

  if (memberError || !memberData) {
    return <Navigate to="/404" />;
  }

  const { firstName, secondName, email } = memberData;

  const handleOpenPopup = () => setIsOpenPopup(true);
  const handleClosePopup = () => setIsOpenPopup(false);
  const handleBack = () => navigate(-1);

  const handleSubmit = async ({
    karma,
    comment,
  }: {
    comment: string;
    karma: string;
  }) => {
    if (accountData && giftAccountData) {
      await createTransaction({
        accountFrom: giftAccountData['@id'],
        accountTo: accountData['@id'],
        amount: +karma,
        comment,
      });

      queryClient.invalidateQueries();

      handleOpenPopup();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <button className={styles.back} onClick={handleBack}>
            <StepBackIcon />
            <p>Назад</p>
          </button>
          <div className={styles.profile}>
            <img src={getAvatar(email)} alt={email} />
            <div className={styles['user-wrapper']}>
              <div className={styles['user-info']}>
                <h1>
                  {firstName || 'Незнакомец'} {secondName}
                </h1>
                {memberData.rank && (
                  <Tag
                    content={`#${memberData.rank.rank}`}
                    type={memberData.rank.rank > 3 ? 'secondary' : 'primary'}
                  />
                )}
              </div>
              <p>{email}</p>
            </div>
          </div>
        </div>

        <div className={styles.cards}>
          <KarmaCard
            className={styles['karma-first-card']}
            title="Карма"
            points={accountData?.total}
            loading={isAccountLoading}
            error={!!accountError}
          />
          <GiveCard
            className={styles['karma-second-card']}
            maxPoints={giftAccountData?.total}
            disabled={isGiftAccountLoading || !!giftAccountError}
            onSubmit={handleSubmit}
          />
          <GiveCardMobile
            points={accountData?.total}
            maxPoints={giftAccountData?.total}
            disabled={isGiftAccountLoading || !!giftAccountError}
            onSubmit={handleSubmit}
            className={styles['karma-mobile-card']}
          />
        </div>
      </div>

      <div className={styles.table}>
        <Tabs size="S" tabBarExtraContent={{ left: 'История' }}>
          <TabPane tab="Все" key="1">
            <UserTable data={allMemberTransactions} />
          </TabPane>
          <TabPane tab="Пополнение" key="2">
            <UserTable data={memberTopups} />
          </TabPane>
          <TabPane tab="Списание" key="3">
            <UserTable data={memberWithdrawals} />
          </TabPane>
        </Tabs>
      </div>

      <SuccessPopup
        open={isOpenPopup}
        onClose={handleClosePopup}
        onSuccess={handleClosePopup}
      />
    </div>
  );
};

export default MemberProfile;
