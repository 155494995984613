import React, { forwardRef, memo } from 'react';
import clsx from 'clsx';

import ITypographyProps from './Typography.interface';

import styles from './style.module.scss'

//TODO: можно переписать на более приятный вариант фабрики
const Typography = forwardRef<any, ITypographyProps>(({ className, children, htmlTag }, ref) => {
    switch (htmlTag) {
        case 'h1':
            return <h1 className={clsx(className, styles.h1)} ref={ref} > {children} </h1>;
        case 'h2':
            return <h2 className={clsx(className, styles.h2)} ref={ref} > {children} </h2>;
        case 'h3':
            return <h3 className={clsx(className, styles.h3)} ref={ref} > {children} </h3>;
        case 'h4':
            return <h4 className={clsx(className, styles.h4)} ref={ref} > {children} </h4>;
        case 'h5':
            return <h5 className={clsx(className, styles.h5)} ref={ref} > {children} </h5>;
        case 'h6':
            return <h6 className={clsx(className, styles.h6)} ref={ref} > {children} </h6>;
        case 'p':
            return <p className={clsx(className, styles.h6)} ref={ref} > {children} </p>;
        case 'div':
            return <div className={clsx(className, styles.h6)} ref={ref} > {children} </div>;
        case 'span':
            return <span className={clsx(className, styles.h6)} ref={ref}> {children} </span>;
        default:
            return <p className={clsx(className, styles.h6)} ref={ref} > {children} </p>;
    }
});

export default memo(Typography);