import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import clsx from "clsx";

import IHeaderProps from "../Header.interface";
import headerStyles from "../style.module.scss";
import styles from "./style.module.scss";

const Navigator: FC<Pick<IHeaderProps, "pages">> = ({ pages }) => {
  return (
    <nav className={styles.nav}>
      <div className={styles.container}>
        {pages &&
          pages.map((page) => (
            <li key={page.url} className={headerStyles["link-wrapper"]}>
              <NavLink
                to={page.url}
                className={({ isActive }) =>
                  clsx(headerStyles.link, {
                    [headerStyles.active]: isActive,
                  })
                }>
                {page.icon}
                {page.title}
              </NavLink>
            </li>
          ))}
      </div>
    </nav>
  );
};

export default Navigator;
