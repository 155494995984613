import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { ReactComponent as OkaneIcon } from '../../../assets/svg/icons/16/okane.svg';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Typography from '../../../components/Typography';
import { getResponseError } from '../../../helpers/hydra';
import { createOrder } from '../../../services/api/orders';
import CartSuccessPopup from '../../CartSuccessPopup';
import ConfirmPopup from '../../ConfirmPopup';
import ErrorPopup from '../../ErrorPopup';
import IShopCardProps from './ShopCard.interface';
import styles from './style.module.scss';

const ShopCard: FC<IShopCardProps> = ({
  id,
  title,
  price,
  image = 'https://placehold.jp/500x500.png',
  className,
}) => {
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [successOpened, setSuccessOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const clearError = () => {
    setError(null);
  };

  const handleClose = () => setSuccessOpened(false);

  const handleBuy = async () => {
    setConfirmOpened(false);

    if (loading) return;

    setLoading(true);
    try {
      await createOrder({ comment: '', products: [id] });

      setSuccessOpened(true);

      queryClient.invalidateQueries();
    } catch (error) {
      const errorText = getResponseError(error);
      setError(errorText || 'Произошла ошибка, попробуй еще раз');
    }

    setLoading(false);
  };

  const handleConfirm = () => {
    setConfirmOpened(true);
  };

  const handleDecline = () => {
    setConfirmOpened(false);
  };

  return (
    <>
      <Card className={clsx(styles.container, className)}>
        <img
          className={styles.image}
          src={image}
          alt={title}
          width="500"
          height="500"
        />
        <footer className={styles.footer}>
          <h2 className={styles.title}>{title}</h2>
          <Button
            buttonType="secondary"
            size="S"
            className={styles.button}
            disabled={loading}
            onClick={handleConfirm}
          >
            <Typography className={styles.title}>{price}</Typography>
            <OkaneIcon className={styles.icon} />
          </Button>
        </footer>
      </Card>

      <CartSuccessPopup
        open={successOpened}
        title={title}
        price={price}
        image={image}
        onSuccess={handleClose}
        onClose={handleClose}
      />

      <ConfirmPopup
        open={confirmOpened}
        title="Оформляем заказ?"
        subtitle={`Будет списано ${price} кармы`}
        onAccept={handleBuy}
        onDecline={handleDecline}
        onClose={handleDecline}
      />

      {error && <ErrorPopup open text={error} onClose={clearError} />}
    </>
  );
};

export default ShopCard;
