import { FC } from 'react';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import { ReactComponent as CrossEyesIcon } from '../../assets/svg/illustrations/M/cross-eyes.svg';
import styles from './style.module.scss';
import Button from '../../components/Button';

interface IErrorPopupProps extends Omit<IPopupProps, 'children'> {
  text: string;
}

const ErrorPopup: FC<IErrorPopupProps> = ({ open, text, onClose }) => {
  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            <CrossEyesIcon className={styles.icon} />
            <h2 className={styles.title}>{text}</h2>
          </div>
        </div>
        <Button onClick={onClose} buttonType="secondary">
          Ок
        </Button>
      </div>
    </Popup>
  );
};

export default ErrorPopup;
