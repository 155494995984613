import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IMainAccount } from './mainAccounts';
import { IRank } from './rank';
import { IHydraBase, IHydraTop } from './types';

export interface IUser extends IHydraBase {
  '@type': 'User';
  email: string;
  mainAccount?: Pick<IMainAccount, '@id' | '@type' | 'total'>;
  giftAccount?: Pick<IMainAccount, '@id' | '@type' | 'total'>;
  firstName: string | null;
  secondName: string | null;
  rank: IRank | null;
}

export interface IUserTop extends IUser, IHydraTop {}

export async function getUsers(config?: AxiosRequestConfig) {
  return axios.get<IUser[]>('/users', config);
}

export async function getUser(
  userId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IUserTop>(`/users/${userId}`, config);
}

export async function getMe(config?: AxiosRequestConfig) {
  return axios.get<IUserTop>('/users/me', config);
}
