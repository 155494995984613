import { ChangeEvent, FocusEvent, FC, useState, useRef } from 'react';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

import { ReactComponent as OkaneIcon } from '../../../assets/svg/icons/16/okane.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icons/16/cross.svg';
import Card from '../../../components/Card';

import IGiveCardProps from './GiveCard.interface';

import styles from './style.module.scss';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import clsx from 'clsx';
import { getResponseError } from '../../../helpers/hydra';

interface IFormKarma {
  karma: string;
  comment: string;
}

const COMMENT_MAX_LENGTH = 255;
const COMMENT_ERROR_TYPES = ['required', 'maxLength'];

const GiveCard: FC<IGiveCardProps> = ({
  maxPoints,
  className,
  disabled,
  onSubmit,
}) => {
  const [isFocusedCloseIcon, setIsFocusedCloseIcon] = useState(false);
  const [isFocusedOkaneIcon, setIsFocusedOkaneIcon] = useState(false);

  const commentInputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm<IFormKarma>({
    defaultValues: { karma: '', comment: '' },
  });

  const onSubmitForm: SubmitHandler<IFormKarma> = async (data) => {
    try {
      await onSubmit(data);

      reset();
    } catch (error: any) {
      const errorText = getResponseError(error);

      if (errorText) {
        setError('karma', { message: errorText, type: 'required' });
      }
    }
  };

  const isLesserThan = (score: string) => Number(score) <= (maxPoints || 1000);

  const {
    onChange: handleChangeKarmaInput,
    onBlur: onBlurKarma,
    name: karmaName,
    ref: karmaRef,
  } = register('karma', {
    required: 'Пожалуйста, заполните обязательное поле',
    validate: isLesserThan,
  });

  const {
    onChange: onChangeCommentInput,
    onBlur: onBlurComment,
    name: commentName,
    ref: commentRef,
  } = register('comment', {
    required: {
      value: true,
      message: 'Необходимо оставить комментарий для коллеги',
    },
    maxLength: {
      message: 'Максимальная длина сообщения 255 символов',
      value: COMMENT_MAX_LENGTH,
    },
  });

  const { errors } = useFormState({
    control,
  });

  const handleChangeCommentInput = (event: ChangeEvent<HTMLInputElement>) => {
    const newEvent = { ...event, target: event.target.value.trim() };
    onChangeCommentInput(newEvent);
  };

  const onFocusKarmaInput = () => {
    setIsFocusedOkaneIcon(true);
  };

  const onBlurKarmaInput = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocusedOkaneIcon(false);
    onBlurKarma(e);
  };

  const onFocusCommentInput = () => {
    setIsFocusedCloseIcon(true);
  };

  const onBlurCommentInput = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocusedCloseIcon(false);
    onBlurComment(e);
  };

  const clearCommentInput = () => {
    if (commentInputRef?.current) commentInputRef.current.value = '';
  };
  return (
    <Card type="form" className={clsx(styles.container, className)}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmitForm)}>
        <div className={styles.inputs}>
          <Input
            className={styles.value}
            placeholder="5"
            onFocus={onFocusKarmaInput}
            onBlur={onBlurKarmaInput}
            onChange={handleChangeKarmaInput}
            suffixIcon={
              <OkaneIcon
                className={clsx({
                  [styles[`okane-icon-focused`]]: isFocusedOkaneIcon,
                })}
              />
            }
            invalid={!!(errors.karma || false)}
            name={karmaName}
            ref={karmaRef}
            autoComplete="off"
          />
          <Input
            className={styles.comment}
            placeholder="Комментарий"
            onFocus={onFocusCommentInput}
            onBlur={onBlurCommentInput}
            onChange={handleChangeCommentInput}
            suffixIcon={
              <CloseIcon
                className={clsx(styles['close-icon'], {
                  [styles['focused']]: isFocusedCloseIcon,
                })}
                onClick={clearCommentInput}
              />
            }
            invalid={!!(errors.comment || false)}
            name={commentName}
            ref={mergeRefs([commentRef, commentInputRef])}
          />
        </div>

        <Button
          buttonType="primary"
          size="S"
          type="submit"
          disabled={disabled || isSubmitting}
        >
          Подарить
        </Button>

        <div className={styles.error}>
          {errors.karma?.type === 'required' && <p>{errors.karma.message}</p>}
          {COMMENT_ERROR_TYPES.includes(errors.comment?.type || '') && (
            <p>{errors.comment?.message}</p>
          )}

          {errors.karma?.type === 'validate' && (
            <p>В вашем хранилище нет столько кармы...(</p>
          )}
        </div>
      </form>
    </Card>
  );
};

export default GiveCard;
