import { useQuery } from "@tanstack/react-query";
import { getMe } from "../../services/api/users";

const useMe = () => {
    const {
        isLoading: isUserLoading,
        error: userError,
        data: response,
    } = useQuery(["me"], async () => getMe(), { retry: false });

    const { data: userData } = response || {};

    return { userData, userError, isUserLoading };
};

export default useMe;
