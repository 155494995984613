import { useQuery } from '@tanstack/react-query';
import { getUserTransactions } from '../../services/api/transactions';

const useUserTransactions = (userId: string | number) => {
  const {
    isLoading: isTransactionsLoading,
    error: transactionsError,
    data: response,
  } = useQuery(['userTransactions', userId], async () =>
    getUserTransactions(userId),
  );

  const { data: transactionsData } = response || {};

  return { transactionsData, transactionsError, isTransactionsLoading };
};

export default useUserTransactions;
