import clsx from 'clsx';
import { FC, useState } from 'react';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import { ReactComponent as CrossEyesIcon } from '../../assets/svg/illustrations/M/cross-eyes.svg';
import { getAvatar } from '../../helpers/avatar';
import { defaultFormatString } from '../../helpers/date';
import TransactionPopup from '../../modules/TransactionPopup';
import { TransactionType } from '../../services/api/transactions';
import styles from './style.module.scss';
import { ITableProps, ITableTransaction } from './UserTable.interface';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { getId } from '../../helpers/hydra';

const getTransactionUser = (transaction: ITableTransaction) => {
  const isTopup = transaction.type === TransactionType.Topup;
  const userField = isTopup ? 'from' : 'to';

  return transaction[userField];
};

const getTransactionUserName = (transaction: ITableTransaction) => {
  const user = getTransactionUser(transaction);

  return user.firstName ? `${user.firstName} ${user.secondName}` : user.email;
};

const UserTable: FC<ITableProps> = ({ className, data }) => {
  const [transaction, setTransaction] = useState<ITableTransaction | null>(
    null,
  );

  const handleClose = () => setTransaction(null);
  const handleOpenTransaction = (transaction: ITableTransaction) =>
    setTransaction(transaction);

  return (
    <>
      {!!transaction && (
        <TransactionPopup
          open
          name={getTransactionUserName(transaction)}
          comments={transaction.comment}
          avatar={getAvatar(getTransactionUser(transaction).email)}
          type={transaction.type}
          scores={transaction.amount}
          date={new Date(transaction.createdAt)}
          onClose={handleClose}
        />
      )}

      <div className={clsx(styles.container, className)}>
        <table>
          <tbody>
            <tr></tr>
            {data?.length ? (
              data?.map((transaction, index) => {
                const isTopup = transaction.type === TransactionType.Topup;
                const user = getTransactionUser(transaction);
                const userName = getTransactionUserName(transaction);

                const handleOpenCurrent = () =>
                  handleOpenTransaction(transaction);

                return (
                  <tr key={transaction['@id']} onClick={handleOpenCurrent}>
                    <td className={styles.userName}>
                      <Link
                        className={styles['cell-user-name']}
                        to={`${ROUTES.memberProfile}/${getId(user['@id'])}`}
                      >
                        <div className={styles.avatar}>
                          <img src={getAvatar(user.email)} alt={user.email} />
                        </div>
                        <p className={styles.name}>{userName}</p>
                      </Link>
                    </td>
                    <td>
                      <div className={styles['cell-comment']}>
                        <p>{transaction.comment}</p>
                      </div>
                    </td>
                    <td>
                      <div
                        className={clsx(styles['cell-points'], {
                          [styles.positive]: isTopup,
                        })}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `${
                              isTopup ? '+' : '-'
                            }&nbsp;${transaction.amount.toLocaleString()}`,
                          }}
                        />
                        <OkaneIcon />
                      </div>
                    </td>
                    <td>
                      <div className={styles['cell-date']}>
                        <p>{defaultFormatString(transaction.createdAt)}</p>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className={styles['no-data']}>
                <td>
                  <div>
                    <CrossEyesIcon />
                    <p>История ещё только начинается....</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserTable;
