import { TabPane } from 'rc-tabs';
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as StepBackIcon } from '../../../assets/svg/icons/16/step-back.svg';
import Tabs from '../../../components/Tabs';
import Tag from '../../../components/Tag';
import Typography from '../../../components/Typography';
import UserTable from '../../../components/UserTable';
import { UserContext } from '../../../contexts/user';
import { getAvatar } from '../../../helpers/avatar';
import useGiftAccount from '../../../hooks/api/useGiftAccount';
import useMainAccount from '../../../hooks/api/useMainAccount';
import useUserFilteredTransactions from '../../../hooks/api/useUserFilteredTransactions';
import { KarmaCard } from '../../../modules/Cards';
import styles from './style.module.scss';

const UserProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { firstName, secondName, email, rank } = useContext(UserContext)!;

  const { isAccountLoading, accountData, accountError } = useMainAccount(id!);
  const {
    isAccountLoading: isGiftAccountLoading,
    accountData: giftAccountData,
    accountError: giftAccountError,
  } = useGiftAccount(id!);

  const { allMemberTransactions, memberTopups, memberWithdrawals } =
    useUserFilteredTransactions(id!);

  const handleBack = () => navigate(-1);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <button className={styles.back} onClick={handleBack}>
            <StepBackIcon />
            <p>Назад</p>
          </button>
          <div className={styles.profile}>
            <img src={getAvatar(email)} alt={email} />
            <div className={styles['user-wrapper']}>
              <div className={styles['user-info']}>
                <h1>
                  {firstName} {secondName}
                </h1>
                {rank && (
                  <Tag
                    content={`#${rank.rank}`}
                    type={rank.rank > 3 ? 'secondary' : 'primary'}
                  />
                )}
              </div>
              <p>{email}</p>
            </div>
          </div>
        </div>

        <div className={styles.cards}>
          <KarmaCard
            className={styles['karma-first-card']}
            title="Моя карма"
            points={accountData?.total}
            loading={isAccountLoading}
            error={!!accountError}
          >
            <Link to="/coupon" className={styles.couponLink}>
              <Typography>Активировать купон</Typography>
            </Link>
          </KarmaCard>
          <KarmaCard
            className={styles['karma-second-card']}
            tooltipContent="Эту карму нужно подарить любому коллеге до указанного срока. Неподаренная карма обнулится (("
            title="Подарочная карма"
            points={giftAccountData?.total}
            type="long"
            loading={isGiftAccountLoading}
            error={!!giftAccountError}
          />
        </div>
      </div>

      <div className={styles.table}>
        <Tabs size="S" tabBarExtraContent={{ left: 'История' }}>
          <TabPane tab="Все" key="1">
            <UserTable data={allMemberTransactions} />
          </TabPane>
          <TabPane tab="Пополнение" key="2">
            <UserTable data={memberTopups} />
          </TabPane>
          <TabPane tab="Списание" key="3">
            <UserTable data={memberWithdrawals} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default UserProfile;
