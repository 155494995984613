import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { FC, ReactNode } from 'react';
import Loader from '../components/Loader';
import env from '../config';

interface IKeycloakProvider {
  children: ReactNode;
}

export const keycloak = new Keycloak({
  clientId: env.REACT_APP_AUTH_CRT_CLIENTID,
  realm: env.REACT_APP_AUTH_CRT_REALM,
  url: env.REACT_APP_AUTH_CRT_URL,
});

export const KeycloakProvider: FC<IKeycloakProvider> = ({ children }) => {
  const handleEvent = (event: string) => {
    if (event === 'onAuthSuccess') {
      localStorage.setItem('token', keycloak.token!);
    }
    if (event === 'onAuthRefreshSuccess') {
      localStorage.setItem('token', keycloak.token!);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Loader />}
      onEvent={handleEvent}
    >
      {children}
    </ReactKeycloakProvider>
  );
};
