import { ReactComponent as CrossEyesIcon } from '../../assets/svg/illustrations/M/cross-eyes.svg';
import Typography from '../../components/Typography';
import styles from './style.module.scss';

const Page404 = () => {
  return (
    <div className={styles.container}>
      <CrossEyesIcon className={styles.icon} />
      <Typography className={styles.text}>Страница не найдена</Typography>
    </div>
  );
};

export default Page404;
