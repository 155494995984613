import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IProduct } from './products';
import { IHydraBase, IHydraTop } from './types';

export interface IOrderItem {
  product: IProduct;
  price: number;
  quantity: number;
}

export interface IOrder extends IHydraBase {
  '@type': 'Order';
  items: IOrderItem[];
  total: number;
  buyer: string;
  status: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderTop extends IOrder, IHydraTop {}

export async function getOrders(config?: AxiosRequestConfig) {
  return axios.get<IOrder[]>('/orders', config);
}

export async function getOrder(
  orderId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IOrderTop>(`/orders/${orderId}`, config);
}

export async function createOrder(
  order: { comment: string; products: [string] },
  config?: AxiosRequestConfig,
) {
  return axios.post<IOrderTop>('/orders', order, config);
}

export async function deleteOrder(
  orderId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.delete(`/orders/${orderId}`, config);
}

export async function getOrderItems(
  orderId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get(`/orders/${orderId}/items`, config);
}

export async function getUserOrders(
  userId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IOrder[]>(`/users/${userId}/orders`, config);
}

export async function getUserOrderItems(
  userId: number | string,
  orderId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get(`/users/${userId}/orders/${orderId}/items`, config);
}
