import { IUser } from '../../services/api/users';

export enum UsersSortBy {
  Current = 'current',
  Alltime = 'alltime',
}

export enum UsersSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortHandler = (
  sortBy: UsersSortBy,
  sortOrder: UsersSortOrder,
) => void;
export interface ITableProps {
  className?: string;
  data?: Array<IUser>;
  loading?: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  sortBy?: UsersSortBy;
  sortOrder?: UsersSortOrder;
  setSort?: SortHandler;
}
