import styles from './style.module.scss';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import Typography from '../Typography';
import { useEffect } from 'react';
import { useState } from 'react';
import { getValidIndex, messages } from './helpers';

const Loader = () => {
  const [index, setIndex] = useState(
    getValidIndex(Number(localStorage!.getItem('loaderIndex')) || 0),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((old) => {
        const newIndex = getValidIndex(old + 1);
        localStorage.setItem('loaderIndex', newIndex.toString());

        return newIndex;
      });
    }, 400 * (Math.random() + 1));

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.wrapper}>
      <OkaneIcon className={styles.icon} />
      <Typography className={styles.text}>{messages[index]}</Typography>
    </div>
  );
};

export default Loader;
