import clsx from 'clsx';
import { FC, useState } from 'react';
import { ReactComponent as OkaneIcon } from '../../../assets/svg/icons/16/okane.svg';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import GiveKarmaPopup from '../../GiveKarmaPopup';
import IGiveCardProps from './GiveCardMobile.interface';
import styles from './style.module.scss';

const GiveCardMobile: FC<IGiveCardProps> = ({
  points,
  className,
  disabled,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleSubmitForm = async (value: any) => {
    await onSubmit(value);

    handleClose();
  };

  return (
    <Card className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <p>Карма</p>

        <div className={styles.points}>
          <p>{points}</p>
          <OkaneIcon />
        </div>
      </div>
      <Button
        buttonType="primary"
        size="S"
        disabled={disabled}
        onClick={handleOpen}
      >
        Подарить
      </Button>

      <GiveKarmaPopup
        open={isOpen}
        onSubmit={handleSubmitForm}
        onClose={handleClose}
      />
    </Card>
  );
};

export default GiveCardMobile;
