import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IHydraBase, IHydraTop } from './types';
import { IUser } from './users';

export interface ITransaction extends IHydraBase {
  '@type': 'Transaction';
  accountFrom: string;
  accountTo: string;
  amount: number;
  comment: string;
  logs: string[];
}

export interface ITransactionTop extends ITransaction, IHydraTop {}

export type TTransactionBody = Pick<
  ITransaction,
  'accountFrom' | 'accountTo' | 'amount' | 'comment'
>;

export type ITransactionLogUser = Omit<IUser, 'mainAccount' | 'rank'>;
export interface ITransactionLog extends IHydraBase {
  from: ITransactionLogUser;
  to: ITransactionLogUser;
  amount: number;
  comment: string;
  createdAt: string;
}

export enum TransactionType {
  Topup = 'topup',
  Withdrawal = 'withdrawal',
}

export async function getTransactions(config?: AxiosRequestConfig) {
  return axios.get<ITransaction[]>('/transactions', config);
}

export async function getTransaction(
  transactionId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<ITransactionTop>(`/transactions/${transactionId}`, config);
}

export async function createTransaction(
  transaction: TTransactionBody,
  config?: AxiosRequestConfig<TTransactionBody>,
) {
  return axios.post<ITransactionTop>('/transactions', transaction, config);
}

export async function getUserTransactions(
  userId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<ITransactionLog[]>(`/users/${userId}/logs`, config);
}
