import { useContext, useState } from 'react';
import GrillTable from './components/GrillTable';
import { UserContext } from '../../contexts/user';
import { useGrillPageData } from './hooks';
import styles from './style.module.scss';
import { TGrillSortBy, TGrillSortOrder } from './types';

const Grill = () => {
  const [search, setSearchValue] = useState<string>('');
  const [sortBy, setSortBy] = useState<TGrillSortBy>('total');
  const [sortOrder, setSortOrder] = useState<TGrillSortOrder>('desc');

  const { firstName, email } = useContext(UserContext)!;

  const { users, isUsersLoading } = useGrillPageData({
    search,
    sortBy,
    sortOrder,
  });


  const handleSort = (sortBy: TGrillSortBy, sortOrder: TGrillSortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles['greeting-title']}>
            <h1>Привет, {firstName || email}!</h1>
          </div>
          <p>Вот, как выглядит рейтинг Proжарки сегодня</p>
        </div>
      </div>

      <div className={styles.table}>
        <GrillTable
          data={users}
          loading={isUsersLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSearchValue={setSearchValue}
          setSort={handleSort} />
      </div>
    </div>
  );
};

export default Grill;
