import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IHydraBase, IHydraTop } from './types';

export interface IGiftAccount extends IHydraBase {
  '@type': 'GiftAccount';
  total: number;
  owner: string;
  outTransactions: string[];
  inTransactions: string[];
}

export interface IGiftAccountTop extends IGiftAccount, IHydraTop {}

export async function getGiftAccounts(config?: AxiosRequestConfig) {
  return axios.get<IGiftAccount[]>('/gift_accounts', config);
}

export async function getGiftAccount(
  accountId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IGiftAccountTop>(`/gift_accounts/${accountId}`, config);
}

export async function getUserGiftAccount(
  userId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IGiftAccountTop>(`/users/${userId}/gift_account`, config);
}
