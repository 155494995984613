import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import Portal from '../Portal';
import IPopupProps from './Popup.interface';
import styles from './style.module.scss';

const Popup: FC<IPopupProps> = ({ open, onClose, children, className }) => {
  const [active, setActive] = useState(false);
  const backdrop = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const transitionEnd = () => setActive(open);

    const clickHandler = (e: MouseEvent) =>
      e.target === backdrop.current && onClose();

    const el = backdrop.current;

    if (el) {
      el.addEventListener('transitionend', transitionEnd);
      el.addEventListener('click', clickHandler);
    }

    return () => {
      if (el) {
        el.removeEventListener('transitionend', transitionEnd);
        el.removeEventListener('click', clickHandler);
      }

      document.querySelector('#root')?.removeAttribute('inert');
    };
  }, [open, onClose]);

  useEffect(() => {
    setActive(open);
  }, [open]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <div
            ref={backdrop}
            className={clsx(styles.backdrop, {
              [styles['active']]: open && active,
            })}
          >
            <div className={clsx(styles.content, className)}>{children}</div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Popup;
