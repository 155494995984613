import { default as RcTabs } from "rc-tabs";
import { FC } from "react";
import { ITabsProps } from "./Tabs.interface";

const Tabs: FC<ITabsProps> = ({ size = "XS", children, ...rest }) => {
  return (
    <RcTabs prefixCls="tabs" data-size={size} {...rest}>
      {children}
    </RcTabs>
  );
};

export default Tabs;
