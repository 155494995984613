import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import {
  SortHandler,
  UsersSortBy,
  UsersSortOrder,
} from '../../components/Table/Table.interface';
import Tag from '../../components/Tag';
import Typography from '../../components/Typography';
import { UserContext } from '../../contexts/user';
import { getId } from '../../helpers/hydra';
import useGiftAccount from '../../hooks/api/useGiftAccount';
import useMainAccount from '../../hooks/api/useMainAccount';
import { KarmaCard } from '../../modules/Cards';
import { useRatingPageData } from './hooks';
import styles from './style.module.scss';

const Rating = () => {
  const [search, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState(UsersSortBy.Current);
  const [sortOrder, setSortOrder] = useState(UsersSortOrder.Desc);

  const { '@id': id, firstName, email, rank } = useContext(UserContext)!;

  const { usersData, isUsersLoading } = useRatingPageData({
    search,
    sortBy,
    sortOrder,
  });

  const { isAccountLoading, accountData, accountError } = useMainAccount(
    getId(id),
  );
  const {
    isAccountLoading: isGiftAccountLoading,
    accountData: giftAccountData,
    accountError: giftAccountError,
  } = useGiftAccount(getId(id));

  const handleSort: SortHandler = (sortBy, sortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles['greeting-title']}>
            <h1>Привет, {firstName || email}!</h1>
            {rank && (
              <Tag
                content={`#${rank.rank}`}
                type={rank.rank > 3 ? 'secondary' : 'primary'}
              />
            )}
          </div>
          <p>Вот, как выглядит рейтинг кармы сегодня</p>
        </div>

        <div className={styles.cards}>
          <KarmaCard
            className={styles['karma-first-card']}
            title="Моя карма"
            points={accountData?.total}
            loading={isAccountLoading}
            error={!!accountError}
          >
            <Link to="/coupon" className={styles.couponLink}>
              <Typography>Активировать купон</Typography>
            </Link>
          </KarmaCard>
          <KarmaCard
            className={styles['karma-second-card']}
            tooltipContent="Эту карму нужно подарить любому коллеге до указанного срока. Неподаренная карма обнулится (("
            title="Подарочная карма"
            points={giftAccountData?.total}
            type="long"
            loading={isGiftAccountLoading}
            error={!!giftAccountError}
          />
        </div>
      </div>

      <div className={styles.table}>
        <Table
          data={usersData}
          loading={isUsersLoading}
          searchValue={search}
          setSearchValue={setSearchValue}
          sortBy={sortBy}
          sortOrder={sortOrder}
          setSort={handleSort}
        />
      </div>
    </div>
  );
};

export default Rating;
