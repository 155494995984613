import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import { getId } from '../../helpers/hydra';
import { TransactionType } from '../../services/api/transactions';
import useUserTransactions from './useUserTransactions';

const useUserFilteredTransactions = (userId: string | number) => {
  const {
    isTransactionsLoading,
    transactionsError,
    transactionsData: transactions = [],
  } = useUserTransactions(userId!);
  const allMemberTransactions = transactions
    .map((item) => ({
      ...item,
      type:
        getId(item.to['@id']) === userId
          ? TransactionType.Topup
          : TransactionType.Withdrawal,
    }))
    .sort(
      (a, b) =>
        parseISO(b.createdAt).valueOf() - parseISO(a.createdAt).valueOf(),
    );
  const memberTopups = useMemo(
    () =>
      allMemberTransactions.filter(
        ({ type }) => type === TransactionType.Topup,
      ),
    [allMemberTransactions],
  );
  const memberWithdrawals = useMemo(
    () =>
      allMemberTransactions.filter(
        ({ type }) => type === TransactionType.Withdrawal,
      ),
    [allMemberTransactions],
  );

  return {
    isTransactionsLoading,
    transactionsError,
    allMemberTransactions,
    memberTopups,
    memberWithdrawals,
  };
};

export default useUserFilteredTransactions;
