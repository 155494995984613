import { FC } from 'react';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import styles from './style.module.scss';
import Comments from '../../components/Comments';
import { TransactionType } from '../../services/api/transactions';

interface ITransactionPopupProps extends Omit<IPopupProps, 'children'> {
  avatar: string;
  type: TransactionType;
  name: string;
  scores: number;
  comments: string;
  date: Date;
}

const TransactionPopup: FC<ITransactionPopupProps> = ({
  open,
  avatar,
  type,
  name,
  scores,
  comments,
  date,
  onClose,
}) => {
  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <Comments
          avatar={avatar}
          type={type === TransactionType.Topup ? 'primary' : 'secondary'}
          transactionType={type}
          name={name}
          scores={scores}
          comments={comments}
          date={date}
          onClose={onClose}
        />
      </div>
    </Popup>
  );
};

export default TransactionPopup;
