import { FC } from 'react';
import Input from '../Input';
import styles from './style.module.scss';
import { ITableProps, UsersSortBy, UsersSortOrder } from './Table.interface';

import { ReactComponent as SearchIcon } from '../../assets/svg/icons/16/search.svg';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import { ReactComponent as CrossEyesIcon } from '../../assets/svg/illustrations/M/cross-eyes.svg';
import clsx from 'clsx';
import Typography from '../Typography';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { getAvatar } from '../../helpers/avatar';
import { getId } from '../../helpers/hydra';
import Spinner from '../Spinner';

const Table: FC<ITableProps> = ({
  className,
  data,
  loading,
  searchValue,
  setSearchValue,
  sortBy,
  sortOrder,
  setSort,
}) => {
  const isAsc = sortOrder === UsersSortOrder.Asc;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchValue) {
      setSearchValue(e.target.value);
    }
  };

  const handleSetSortByCurrent = () => {
    if (setSort) {
      setSort(
        UsersSortBy.Current,
        isAsc ? UsersSortOrder.Desc : UsersSortOrder.Asc,
      );
    }
  };

  const handleSetSortByAlltime = () => {
    if (setSort) {
      setSort(
        UsersSortBy.Alltime,
        isAsc ? UsersSortOrder.Desc : UsersSortOrder.Asc,
      );
    }
  };

  return (
    <div className={clsx(styles.container, className)}>
      <table>
        <thead>
          <tr>
            <td className={styles['input-cell']}>
              <Input
                placeholder="Поиск соратника"
                className={styles.input}
                prefixIcon={<SearchIcon />}
                value={searchValue}
                onChange={handleSearch}
              />
            </td>
            <td>
              <span
                className={clsx({
                  [styles.activeSort]: sortBy === UsersSortBy.Current,
                  [styles.activeSortAsc]: isAsc,
                })}
                onClick={handleSetSortByCurrent}
              >
                Сейчас
              </span>
            </td>
            <td>
              <span
                className={clsx({
                  [styles.activeSort]: sortBy === UsersSortBy.Alltime,
                  [styles.activeSortAsc]: isAsc,
                })}
                onClick={handleSetSortByAlltime}
              >
                Всё время
              </span>
            </td>
          </tr>
        </thead>

        {loading && (
          <tbody>
            <tr className={styles.spinnerRow}>
              <td className={styles.spinnerCell} colSpan={3}>
                <Spinner height={64} />
              </td>
            </tr>
          </tbody>
        )}

        {!loading && (
          <tbody>
            <tr></tr>
            {data?.length ? (
              data?.map((user, index) => (
                <tr key={user['@id']}>
                  <td>
                    <Link
                      className={styles['cell-user-name']}
                      to={`${ROUTES.memberProfile}/${getId(user['@id'])}`}
                    >
                      <div className={styles['number']}>{++index}</div>
                      <div className={styles['avatar']}>
                        <img src={getAvatar(user.email)} alt={user.email} />
                      </div>
                      <div className={styles['name']}>
                        {user.firstName || user.email} {user.secondName}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link
                      className={styles['cell-points']}
                      to={`${ROUTES.memberProfile}/${getId(user['@id'])}`}
                    >
                      <div className={styles['now-points']}>
                        {user.mainAccount?.total.toLocaleString()}
                      </div>
                      <OkaneIcon className={styles['now-points-icon']} />
                    </Link>
                  </td>
                  <td>
                    <Link
                      className={styles['cell-points']}
                      to={`${ROUTES.memberProfile}/${getId(user['@id'])}`}
                    >
                      <div>{user.mainAccount?.total.toLocaleString()}</div>
                      <OkaneIcon />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles['no-data']}>
                <td colSpan={3}>
                  <div>
                    <CrossEyesIcon className={styles['no-data_icon']} />
                    <Typography className={styles['no-data_text']}>
                      Этот странник ещё не присоединился к нам
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
