import { useQuery } from '@tanstack/react-query';
import { getUserGiftAccount } from '../../services/api/giftAccounts';

const useGiftAccount = (userId: string | number) => {
  const {
    isLoading: isAccountLoading,
    error: accountError,
    data: response,
  } = useQuery(['giftAccount', userId], async () => getUserGiftAccount(userId));

  const { data: accountData } = response || {};

  return { accountData, accountError, isAccountLoading };
};

export default useGiftAccount;
