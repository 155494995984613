import clsx from 'clsx';
import { useContext, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/icons/16/search.svg';
import { ReactComponent as JewIcon } from '../../assets/svg/illustrations/M/jew.svg';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Spinner from '../../components/Spinner';
import Typography from '../../components/Typography';
import { UserContext } from '../../contexts/user';
import { getId } from '../../helpers/hydra';
import useProducts from '../../hooks/api/useProducts';
import useUserOrders from '../../hooks/api/useUserOrders';
import ShopCard from '../../modules/Cards/ShopCard';
import OrdersPopup from '../../modules/OrdersPopup';
import { IProduct } from '../../services/api/products';
import styles from './style.module.scss';

const isProductHasValue = (item: Partial<IProduct>, value: string) =>
  item.title!.toLocaleLowerCase().includes(value) ||
  item.description!.toLocaleLowerCase().includes(value);

const Shop = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isOrdersPopupOpened, setOrdersPopupOpened] = useState(false);

  const { '@id': userId } = useContext(UserContext)!;

  const { productsData, isProductsLoading } = useProducts();

  const { ordersData: userOrders } = useUserOrders(getId(userId));

  const handleOpenOrders = () => {
    setOrdersPopupOpened(true);
  };

  const handleCloseOrders = () => {
    setOrdersPopupOpened(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  const ordersPopup = (
    <OrdersPopup
      open={isOrdersPopupOpened}
      orders={userOrders || []}
      onClose={handleCloseOrders}
    />
  );

  if (isProductsLoading) {
    return (
      <>
        {ordersPopup}
        <div className={clsx(styles.container, styles.container__centered)}>
          <Spinner height={64} />
        </div>
      </>
    );
  }

  if (!productsData?.length) {
    return (
      <>
        {ordersPopup}
        <div className={clsx(styles.container, styles.container__centered)}>
          <JewIcon className={styles.icon} />
          <Typography className={styles.text}>
            Терпение, мой друг, торговцы еще не готовы
          </Typography>
        </div>
      </>
    );
  }

  const filteredProducts = productsData.filter(
    (item) => !searchValue || isProductHasValue(item, searchValue),
  );

  return (
    <>
      {ordersPopup}
      <div className={styles.container}>
        <div className={styles.topBar}>
          <Input
            placeholder="Поиск товара"
            className={styles.input}
            prefixIcon={<SearchIcon />}
            value={searchValue}
            onChange={handleSearch}
          />
          {!!userOrders?.length && (
            <Button
              className={styles.cartButton}
              buttonType="secondary"
              onClick={handleOpenOrders}
            >
              <div className={styles.rect}>
                <div className={styles.badge}>{userOrders?.length}</div>
              </div>
            </Button>
          )}
        </div>
        <div className={styles.row}>
          {filteredProducts.map(
            ({ title, description, price, image, '@id': id }) => (
              <div key={title} className={styles.col}>
                <ShopCard
                  className={styles.card}
                  id={id}
                  title={title}
                  description={description}
                  price={price}
                  image={image}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </>
  );
};

export default Shop;
