import clsx from "clsx";
import { forwardRef, memo } from "react";

import IPopupProps from "./Popup.interface";

import styles from "./style.module.scss";

const Popup = forwardRef<any, IPopupProps>(
  (
    {
      content,
      className,
      triggered = false,
      onMouseLeave,
      onMouseEnter,
      style,
      arrowPosition = "TopCenter",
    },
    ref
  ) => {
    return (
      <span
        ref={ref}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        style={style}
        className={clsx(
          styles.popup,
          {
            className,
            [styles[`arrow-position-${arrowPosition}`]]: arrowPosition,
            [styles[`transition-in`]]: triggered,
            [styles[`transition-out`]]: !triggered,
          },
          styles[arrowPosition]
        )}>
        {content}
      </span>
    );
  }
);

export default memo(Popup);
