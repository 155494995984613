import clsx from 'clsx';
import { useContext } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { ReactComponent as StarIcon } from '../assets/svg/icons/24/star.svg';
import { ReactComponent as ShopIcon } from '../assets/svg/icons/24/store.svg';
import { ReactComponent as BurgerIcon } from '../assets/svg/icons/24/burger.svg';
import Header from '../components/Header';
import { UserContext } from '../contexts/user';
import { getAvatar } from '../helpers/avatar';
import { getId } from '../helpers/hydra';
import styles from './style.module.scss';

const pages = [
  { url: 'rating', title: 'Рейтинг', icon: <StarIcon /> },
  { url: 'shop', title: 'Лавка', icon: <ShopIcon /> },
  { url: 'grill', title: 'Proжарка', icon: <BurgerIcon /> },
];

const Layout = () => {
  const isLogin = useMatch('/');
  const user = useContext(UserContext);

  return (
    <div className={clsx(styles.container, isLogin && styles.login)}>
      {!isLogin && user && (
        <div className={styles['header-wrapper']}>
          <Header
            profile={{
              avatar: getAvatar(user.email),
              url: `/profile/user/${getId(user['@id'])}`,
            }}
            pages={pages}
          />
        </div>
      )}

      <div className={styles.layout}>
        <div className={styles['content-wrapper']}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
