import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IHydraBase, IHydraTop } from './types';

export interface IMainAccount extends IHydraBase {
  '@type': 'MainAccount';
  total: number;
  owner: string;
  outTransactions: string[];
  inTransactions: string[];
}

export interface IMainAccountTop extends IMainAccount, IHydraTop {}

export async function getMainAccounts(config?: AxiosRequestConfig) {
  return axios.get<IMainAccount[]>('/main_accounts', config);
}

export async function getMainAccount(
  accountId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IMainAccountTop>(`/main_accounts/${accountId}`, config);
}

export async function getUserMainAccount(
  userId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IMainAccountTop>(`/users/${userId}/main_account`, config);
}
