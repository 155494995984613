import { FC, ReactNode } from 'react';
import Loader from '../components/Loader';
import { UserContext } from '../contexts/user';
import useMe from '../hooks/api/useMe';

interface IUserProvider {
  children: ReactNode;
}

export const UserProvider: FC<IUserProvider> = ({ children }) => {
  const { userData, isUserLoading } = useMe();

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <UserContext.Provider value={userData || null}>
      {children}
    </UserContext.Provider>
  );
};
