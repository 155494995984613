import { FC } from "react";
import Popup from "../../components/Popup";
import IPopupProps from "../../components/Popup/Popup/Popup.interface";
import { ReactComponent as SmileIcon } from "../../assets/svg/illustrations/M/smile.svg";
import styles from "./style.module.scss";
import Button from "../../components/Button";

interface ISuccessPopupProps extends Omit<IPopupProps, "children"> {
  onSuccess?: () => void;
}

const SuccessPopup: FC<ISuccessPopupProps> = ({ open, onClose, onSuccess }) => {
  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles["content-wrapper"]}>
          <div className={styles.content}>
            <SmileIcon className={styles.icon} />
            <h2 className={styles.title}>Карма отправлена</h2>
            <p className={styles.description}>
              Да воздастся тебе за добрые дела твои!
            </p>
          </div>
        </div>
        <Button onClick={onSuccess} buttonType="secondary">
          Супер!
        </Button>
      </div>
    </Popup>
  );
};

export default SuccessPopup;
