import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { ReactComponent as LoginSvg } from '../../assets/svg/login.svg';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import styles from './style.module.scss';

const Login = () => {
  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Navigate to="rating" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <LoginSvg className={styles.background} />
        <div className={styles.content}>
          <div className={styles.content_text}>
            <Typography className={styles.greeting} htmlTag="h1">
              Добро пожаловать, путешественник!
            </Typography>
            <Typography className={styles.greeting_info}>
              Перед тобой вход в хранилище кармы сотрудников Creative.
            </Typography>
          </div>
          <Button
            className={styles.button}
            size="L"
            buttonType="primary"
            onClick={login}
          >
            Войти
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
