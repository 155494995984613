import clsx from 'clsx';
import { FC, memo } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/icons/16/cross.svg';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import { TransactionType } from '../../services/api/transactions';
import ICommentProps from './Comments.interface';

import styles from './style.module.scss';

const Comments: FC<ICommentProps> = ({
  avatar,
  name,
  scores,
  comments,
  transactionType,
  type = 'primary',
  date = new Date(),
  onClose,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={avatar} alt="test" />
        <CloseIcon onClick={onClose} />
      </div>
      <h2 className={styles.name}>{name}</h2>
      <div
        className={clsx(styles.scores, {
          [styles[`type-${type}`]]: type,
        })}
      >
        <h2>
          {transactionType === TransactionType.Topup ? '+' : '-'}
          {scores}
        </h2>
        <OkaneIcon onClick={onClose} width={24} height={24} />
      </div>
      <h3 className={styles['comment-title']}>Комментарий</h3>
      <p className={styles.comments}>{comments}</p>
      <p className={styles['date-title']}>{date.toLocaleDateString()}</p>
    </div>
  );
};

export default memo(Comments);
