import { FC } from 'react';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import Typography from '../../components/Typography';
import styles from './style.module.scss';

interface ICartSuccessPopupProps extends Omit<IPopupProps, 'children'> {
  title: string;
  price: number;
  image?: string;
  onSuccess?: () => void;
}

const CartSuccessPopup: FC<ICartSuccessPopupProps> = ({
  open,
  title,
  price,
  image,
  onClose,
  onSuccess,
}) => {
  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            <h2 className={styles.title}>Заказ оформлен</h2>
            {!!image && (
              <img
                className={styles.image}
                src={image}
                alt={title}
                width="100"
                height="100"
              />
            )}
            <Typography className={styles.productTitle}>
              {title}{' '}
              <Typography className={styles.productPrice}>
                {price} <OkaneIcon className={styles.icon} />
              </Typography>
            </Typography>
            <p className={styles.description}>Поздравляем с покупкой!</p>
          </div>
        </div>
        <Button onClick={onSuccess} buttonType="secondary">
          Супер
        </Button>
      </div>
    </Popup>
  );
};

export default CartSuccessPopup;
