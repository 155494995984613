import { useQuery } from '@tanstack/react-query';
import { getProducts } from '../../services/api/products';

const useProducts = () => {
  const {
    isLoading: isProductsLoading,
    error: productsError,
    data: response,
  } = useQuery(['products'], async () => getProducts());

  const { data: productsData } = response || {};

  return { productsData, productsError, isProductsLoading };
};

export default useProducts;
