import clsx from 'clsx';
import { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/svg/Logo.svg';
import { ROUTES } from '../../constants/routes';
import IHeaderProps from './Header.interface';
import Navigator from './Navigator';
import styles from './style.module.scss';

const Header: FC<IHeaderProps> = ({ pages, profile }) => {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    if (profile.url) navigate(profile.url);
  };

  const navigateToMain = () => {
    navigate(ROUTES.rating);
  };

  return (
    <>
      <header className={styles.container}>
        <div className={styles.header}>
          <div className={styles['logo-wrapper']} onClick={navigateToMain}>
            <LogoIcon />
          </div>
          <ul className={styles.content}>
            {pages &&
              pages.map((page) => (
                <li key={page.url} className={styles['link-wrapper']}>
                  <NavLink
                    to={page.url}
                    className={({ isActive }) =>
                      clsx(styles.link, {
                        [styles.active]: isActive,
                      })
                    }
                  >
                    {page.title}
                  </NavLink>
                </li>
              ))}
            <li className={styles.profile} onClick={navigateToProfile}>
              <div className={styles['link-wrapper']}>
                <NavLink
                  to={profile.url}
                  className={({ isActive }) =>
                    clsx(styles.link, {
                      [styles.active]: isActive,
                    })
                  }
                >
                  Профиль
                </NavLink>
              </div>
              <img src={profile.avatar} alt={profile.avatar} />
            </li>
          </ul>
        </div>
      </header>

      <Navigator pages={pages} />
    </>
  );
};

export default Header;
