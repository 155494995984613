import { useQuery } from '@tanstack/react-query';
import {
  UsersSortBy,
  UsersSortOrder,
} from '../../components/Table/Table.interface';
import { getUsers } from '../../services/api/users';

type HookParams = {
  search?: string;
  sortBy?: UsersSortBy;
  sortOrder?: UsersSortOrder;
};

export const useRatingPageData = ({
  search,
  sortBy = UsersSortBy.Current,
  sortOrder = UsersSortOrder.Desc,
}: HookParams = {}) => {
  const {
    isLoading: isUsersLoading,
    error: usersError,
    data: response,
  } = useQuery(['users'], async () => getUsers());
  const { data: usersData = [] } = response || {};

  // const sortProperty =
  //   sortBy === UsersSortBy.Alltime ? 'allTimePoints' : 'nowPoints';
  const sortedData = usersData.sort(
    (a, b) =>
      ((a.mainAccount?.total || 0) - (b.mainAccount?.total || 0)) *
      (sortOrder === UsersSortOrder.Asc ? 1 : -1),
  );

  const preparedSearch = search?.toLowerCase();
  const filteredData = preparedSearch
    ? sortedData.filter(
        (user) =>
          user.firstName?.toLowerCase().includes(preparedSearch) ||
          user.secondName?.toLowerCase().includes(preparedSearch) ||
          user.email.toLowerCase().includes(preparedSearch),
      )
    : sortedData;

  return {
    isUsersLoading,
    usersError,
    usersData: filteredData,
  };
};
