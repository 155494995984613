import { cloneElement, FC, memo, useEffect, useRef, useState } from 'react';

import ITooltipProps, { TPositionsMap } from './Tooltip.interface';

import calculatePosition from './calculatePosition';
import Popup from './Popup';

const Tooltip: FC<ITooltipProps> = ({
  children,
  position = 'TopCenter',
  content,
  className,
}) => {
  const [isHoveredElement, setIsHoveredElement] = useState(false);
  const [isHoveredPopup, setIsHoveredPopup] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const elementRef = useRef<null | HTMLElement>(null);

  const positionMap: TPositionsMap = {
    TopLeft: 'BottomRight',
    TopCenter: 'BottomCenter',
    TopRight: 'BottomLeft',
    LeftCenter: 'RightCenter',
    RightCenter: 'LeftCenter',
    BottomRight: 'TopLeft',
    BottomCenter: 'TopCenter',
    BottomLeft: 'TopRight',
  };

  const onHoverElement = () => {
    setIsHoveredElement(true);
  };

  const outHoverElement = () => {
    setIsHoveredElement(false);
  };

  const onHoverPopup = () => {
    setIsHoveredPopup(true);
  };

  const outHoverPopup = () => {
    setIsHoveredPopup(false);
  };

  useEffect(() => {
    const el = elementRef?.current;
    el?.addEventListener('mouseover', onHoverElement, false);
    el?.addEventListener('mouseout', outHoverElement, false);

    return () => {
      el?.removeEventListener('mouseover', onHoverElement, false);
      el?.removeEventListener('mouseout', outHoverElement, false);
    };
  }, []);

  useEffect(() => {
    if (!isHoveredElement && !isHoveredPopup) {
      setIsVisible(false);
    }

    if (isHoveredElement || isHoveredPopup) {
      setIsVisible(true);
    }
  }, [isHoveredElement, isHoveredPopup]);

  const sizes = elementRef?.current?.getBoundingClientRect();
  const scrollOffset = window.scrollY;

  return (
    <>
      <Popup
        onMouseLeave={outHoverPopup}
        onMouseEnter={onHoverPopup}
        arrowPosition={positionMap[position]}
        content={content}
        triggered={isVisible}
        style={calculatePosition(position, sizes, scrollOffset)}
        className={className}
      />
      {cloneElement(children, { ref: elementRef })}
    </>
  );
};

export default memo(Tooltip);
