import Avatar1 from '../assets/svg/avatars/avatar-1.svg';
import Avatar2 from '../assets/svg/avatars/avatar-2.svg';
import Avatar3 from '../assets/svg/avatars/avatar-3.svg';
import Avatar4 from '../assets/svg/avatars/avatar-4.svg';
import Avatar5 from '../assets/svg/avatars/avatar-5.svg';
import Avatar6 from '../assets/svg/avatars/avatar-6.svg';
import Avatar7 from '../assets/svg/avatars/avatar-7.svg';
import Avatar8 from '../assets/svg/avatars/avatar-8.svg';

export const avatarsMap: { [key: number]: string } = {
  1: Avatar1,
  2: Avatar2,
  3: Avatar3,
  4: Avatar4,
  5: Avatar5,
  6: Avatar6,
  7: Avatar7,
  8: Avatar8,
};

export const avatarsCount = Object.keys(avatarsMap).length;

const getSumOfDigits = (num: number) => {
  return String(num)
    .split('')
    .reduce((acc, curr) => acc + Number(curr), 0);
};

export const getAvatarId = (email: string = '1') => {
  let sum = email.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  while (sum > avatarsCount) {
    sum = getSumOfDigits(sum);

    if (sum.toString().length === 1 && sum > avatarsCount) {
      sum = Math.floor(sum / 2);
    }
  }

  return sum;
};

export const getAvatar = (email: string) => avatarsMap[getAvatarId(email)];
