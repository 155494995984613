import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { IHydraBase, IHydraTop } from './types';

export interface IProduct extends IHydraBase {
  '@type': 'Product';
  title: string;
  price: number;
  description: string;
  image?: string;
}

export interface IProductTop extends IProduct, IHydraTop {}

export async function getProducts(config?: AxiosRequestConfig) {
  return axios.get<IProduct[]>('/products', config);
}

export async function getProduct(
  productId: number | string,
  config?: AxiosRequestConfig,
) {
  return axios.get<IProductTop>(`/products/${productId}`, config);
}
