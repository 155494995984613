import { FC, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import IPortalProps from "./Portal.interface";

const Portal: FC<IPortalProps> = ({ children, parent, className }) => {
  const el = useMemo(() => document.createElement("div"), []);
  useEffect(() => {
    const target = parent?.appendChild ? parent : document.body;
    const classList = ["portal-container"];

    if (className) className.split(" ").forEach((item) => classList.push(item));

    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el, parent, className]);
  return ReactDOM.createPortal(children, el);
};

export default Portal;
