export const messages = [
  'Загрузка колеса сансары',
  'Чтение кармического следа',
  'Настройка благоприятной ауры',
  'Поиск смысла жизни',
];

export const messagesCount = messages.length;

export const getValidIndex = (index: number) =>
  Math.max(0, index % messagesCount);
