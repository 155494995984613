import { useKeycloak } from '@react-keycloak/web';
import { FC, ReactNode, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/user';

interface IRouteProps {
  children: ReactNode;
}

const ProtectedRoute: FC<IRouteProps> = ({ children }) => {
  const user = useContext(UserContext);
  const { keycloak } = useKeycloak();

  if (!user || !keycloak?.authenticated) {
    return <Navigate to="/" />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
