import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../layouts';
import Page404 from '../pages/404';
import { Coupon } from '../pages/coupon';
import Grill from '../pages/grill';
import { Login } from '../pages/login';
import { MemberProfile, UserProfile } from '../pages/profiles';
import Rating from '../pages/rating';
import Shop from '../pages/shop';
import ProtectedRoute from './utils';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Login />} />
          <Route path="profile">
            <Route path="member">
              <Route
                path=":id"
                element={
                  <ProtectedRoute>
                    <MemberProfile />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="user">
              <Route
                path=":id"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          <Route
            path="shop"
            element={
              <ProtectedRoute>
                <Shop />
              </ProtectedRoute>
            }
          />
          <Route
            path="rating"
            element={
              <ProtectedRoute>
                <Rating />
              </ProtectedRoute>
            }
          />
          <Route
            path="grill"
            element={
              <ProtectedRoute>
                <Grill />
              </ProtectedRoute>
            }
          />
          <Route
            path="coupon"
            element={
              <ProtectedRoute>
                <Coupon />
              </ProtectedRoute>
            }
          />
          <Route
            path="coupon/:code"
            element={
              <ProtectedRoute>
                <Coupon />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
