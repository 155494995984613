import clsx from 'clsx';
import { nextFriday } from 'date-fns';
import { FC, PropsWithChildren } from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/svg/icons/16/info.svg';
import { ReactComponent as OkaneIcon } from '../../../assets/svg/icons/16/okane.svg';
import Card from '../../../components/Card';
import Spinner from '../../../components/Spinner';
import Tooltip from '../../../components/Tooltip';
import { defaultFormat } from '../../../helpers/date';
import IKarmaCardProps from './KarmaCard.interface';
import styles from './style.module.scss';

const KarmaCard: FC<PropsWithChildren<IKarmaCardProps>> = ({
  type = 'default',
  title,
  points,
  className,
  tooltipContent = '',
  date,
  loading,
  error,
  children,
}) => {
  const isLong = type === 'long';

  return (
    <Card type={type} className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <p>{title}</p>

        {isLong && (
          <Tooltip content={tooltipContent} position="BottomLeft">
            <InfoIcon />
          </Tooltip>
        )}
      </div>

      {children}

      <div className={styles.footer}>
        <div className={styles.points}>
          {loading && <Spinner height={20} />}
          {error && <span className={styles.error}>Ошибка загрузки</span>}
          {!loading && !error && (
            <>
              <p>{points}</p>
              <OkaneIcon />
            </>
          )}
        </div>

        {isLong && (
          <p className={styles.date}>{defaultFormat(nextFriday(new Date()))}</p>
        )}
      </div>
    </Card>
  );
};

export default KarmaCard;
