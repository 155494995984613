import Axios from 'axios';
import env from '../config';

const axios = Axios.create({
  baseURL: `${env.REACT_APP_KARMA_API}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    const validConfig = config ?? {};
    validConfig.headers = validConfig.headers ?? {};

    if (token) {
      validConfig.headers.Authorization = `Bearer ${token}`;
    }

    return validConfig;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    const isMember = Object.hasOwnProperty.call(response.data, 'hydra:member');
    return {
      ...response,
      ...(isMember && {
        totalItems: response.data['hydra:totalItems'],
      }),
      data: isMember ? response.data['hydra:member'] : response.data,
    };
  },
  (error) => Promise.reject(error),
);

export default axios;
