export const hydraErrors: Record<string, string>[] = [
  {
    en: 'Monthly transaction limit exceeded',
    ru: 'Достигнут лимит переводов одному человеку за месяц',
  },
  {
    en: 'amount: This value should be greater than 0.',
    ru: 'Количество должно быть больше 0',
  },
  {
    en: 'Coupon not found',
    ru: 'Купон не найден',
  },
];

export const getTranslatedError = (
  {
    langFrom = 'en',
    langTo = 'ru',
    error = '',
  }: {
    langFrom?: string;
    langTo?: string;
    error: string;
  } = { error: '' },
) => {
  return hydraErrors.find(
    (item) => item[langFrom].toLowerCase() === error.toLowerCase(),
  )?.[langTo];
};

export const getId = (fullId: string) => fullId.split('/').pop()!;

export const getResponseError = (error: any): string | undefined | null => {
  const errorText = error?.response?.data['hydra:description'];

  if (errorText) {
    return getTranslatedError({ error: errorText }) || errorText;
  }

  return;
};
