import { FC } from 'react';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import styles from './style.module.scss';
import Button from '../../components/Button';

interface IConfirmPopupProps extends Omit<IPopupProps, 'children'> {
  title: string;
  subtitle?: string;
  onAccept?: () => void;
  onDecline?: () => void;
}

const ConfirmPopup: FC<IConfirmPopupProps> = ({
  open,
  title,
  subtitle,
  onClose,
  onAccept,
  onDecline,
}) => {
  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            <h2 className={styles.title}>{title}</h2>
            {subtitle && <p className={styles.description}>{subtitle}</p>}
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            className={styles.btn}
            onClick={onAccept}
            buttonType="primary"
          >
            Да
          </Button>
          <Button
            className={styles.btn}
            onClick={onDecline}
            buttonType="secondary"
          >
            Нет
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmPopup;
