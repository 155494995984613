import { useQuery } from "@tanstack/react-query";
import useUserFilteredTransactions from "../../../hooks/api/useUserFilteredTransactions";
import { getUser } from "../../../services/api/users";

export const useMemberPageData = (id: string | number) => {
  const {
    isLoading: isMemberLoading,
    error: memberError,
    data: response,
  } = useQuery(["member", id], async () => getUser(id));
  const { data: memberData } = response || {};

  const {
    isTransactionsLoading,
    transactionsError,
    allMemberTransactions,
    memberTopups,
    memberWithdrawals,
  } = useUserFilteredTransactions(id!);

  return {
    memberData,
    memberError,
    isMemberLoading,
    allMemberTransactions,
    memberTopups,
    memberWithdrawals,
    transactionsError,
    isTransactionsLoading,
  };
};
