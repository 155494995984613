import { FC } from 'react';
import Input from '../../../../components/Input';
import { ITableProps } from './GrillTable.interface';
import styles from './style.module.scss';

import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/icons/16/search.svg';
import { ReactComponent as BurgerIcon } from '../../../../assets/svg/icons/24/burger.svg';
import { ReactComponent as CrossEyesIcon } from '../../../../assets/svg/illustrations/M/cross-eyes.svg';
import Spinner from '../../../../components/Spinner';
import Typography from '../../../../components/Typography';
import { getAvatar } from '../../../../helpers/avatar';

const GrillTable: FC<ITableProps> = ({
  className,
  data,
  loading,
  searchValue,
  setSearchValue,
  sortBy,
  sortOrder,
  setSort,
}) => {
  const isAsc = sortOrder === 'asc';

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchValue) {
      setSearchValue(e.target.value);
    }
  };

  const handleSetSortByTotal = () => {
    if (setSort) {
      setSort(
        'total',
        sortBy !== 'total' && sortOrder ? sortOrder : isAsc ? 'desc' : 'asc',
      );
    }
  };

  const handleSetSortByBig = () => {
    if (setSort) {
      setSort(
        'big',
        sortBy !== 'big' && sortOrder ? sortOrder : isAsc ? 'desc' : 'asc',
      );
    }
  };

  const handleSetSortBySmall = () => {
    if (setSort) {
      setSort(
        'small',
        sortBy !== 'small' && sortOrder ? sortOrder : isAsc ? 'desc' : 'asc',
      );
    }
  };

  return (
    <div className={clsx(styles.container, className)}>
      <table>
        <thead>
          <tr>
            <td className={styles['input-cell']}>
              <Input
                placeholder="Поиск участника"
                className={styles.input}
                prefixIcon={<SearchIcon />}
                value={searchValue}
                onChange={handleSearch}
              />
            </td>
            <td>
              <span
                className={clsx({
                  [styles.activeSort]: sortBy === 'small',
                  [styles.activeSortAsc]: isAsc,
                })}
                onClick={handleSetSortBySmall}
              >
                S-бургер
              </span>
            </td>
            <td>
              <span
                className={clsx({
                  [styles.activeSort]: sortBy === 'big',
                  [styles.activeSortAsc]: isAsc,
                })}
                onClick={handleSetSortByBig}
              >
                L-бургер
              </span>
            </td>
            <td>
              <span
                className={clsx({
                  [styles.activeSort]: sortBy === 'total',
                  [styles.activeSortAsc]: isAsc,
                })}
                onClick={handleSetSortByTotal}
              >
                Всего
              </span>
            </td>
          </tr>
        </thead>

        {loading && (
          <tbody>
            <tr className={styles.spinnerRow}>
              <td className={styles.spinnerCell} colSpan={3}>
                <Spinner height={64} />
              </td>
            </tr>
          </tbody>
        )}

        {!loading && (
          <tbody>
            <tr></tr>
            {data?.length ? (
              data?.map((user, index) => (
                <tr key={user['name']}>
                  <td>
                    <div
                      className={styles['cell-user-name']}
                    >
                      <div className={styles['number']}><span>{++index}</span></div>
                      <div className={styles['avatar']}>
                        <img src={getAvatar(user.name)} alt={user.name} />
                      </div>
                      <div className={styles['name']}>
                        {user.name}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className={styles['cell-points']}
                    >
                      <div className={styles['small']}>
                        {user.small}
                      </div>
                      <BurgerIcon className={styles['small-icon']} />
                    </div>
                  </td>
                  <td>
                    <div
                      className={styles['cell-points']}
                    >
                      <div className={styles['big']}>{user.big}</div>
                      <BurgerIcon className={styles['big-icon']} />
                    </div>
                  </td>
                  <td>
                    <div
                      className={styles['cell-points']}
                    >
                      <div className={styles['total']}>{user.total}</div>
                      <BurgerIcon className={styles['total-icon']} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles['no-data']}>
                <td colSpan={3}>
                  <div>
                    <CrossEyesIcon className={styles['no-data_icon']} />
                    <Typography className={styles['no-data_text']}>
                      Этот странник ещё не присоединился к нам
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default GrillTable;
