import clsx from 'clsx';
import { FC } from 'react';
import ICardProps from './Card.interface';

import styles from './style.module.scss';

const Card: FC<ICardProps> = ({ type = 'default', className, children }) => {
  return (
    <div
      className={clsx(styles.container, className, {
        [styles[`type-${type}`]]: !!type,
      })}
    >
      {children}
    </div>
  );
};

export default Card;
