import { FC } from 'react';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import styles from './style.module.scss';
import Button from '../../components/Button';
import { IOrder } from '../../services/api/orders';
import Typography from '../../components/Typography';
import { capitalizeFirstLetter } from '../../helpers/string';

interface IOrdersPopupProps extends Omit<IPopupProps, 'children'> {
  orders: IOrder[];
}

const OrdersPopup: FC<IOrdersPopupProps> = ({ open, orders, onClose }) => {
  const items = orders.flatMap((order) => order.items);

  return (
    <Popup onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>
            <h2 className={styles.title}>Твои заказы</h2>
            <div className={styles.list}>
              {items.map(
                (
                  {
                    product: {
                      title,
                      image = 'https://placehold.jp/50x50.png',
                    },
                    price,
                  },
                  index,
                ) => (
                  <div className={styles.item} key={index}>
                    <img className={styles.image} src={image} alt={title} />
                    <Typography className={styles.itemTitle}>
                      {capitalizeFirstLetter(title)}
                    </Typography>
                    <Typography className={styles.itemPrice}>
                      {price}
                      <OkaneIcon className={styles.icon} />
                    </Typography>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
        <Button onClick={onClose} buttonType="secondary">
          Ок
        </Button>
      </div>
    </Popup>
  );
};

export default OrdersPopup;
