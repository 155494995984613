import { TPositions } from "./Popup/Popup.interface";
import { Sizes } from "./Tooltip.interface";

const calculatePosition = (
  position: TPositions,
  sizes: Sizes | undefined,
  offset: number
) => {
  const offsetY = 7;
  const offsetX = 7;
  const offsetArrow = 25;

  if (sizes) {
    const offsetSizes = {
      bottom: sizes.bottom + offset,
      height: sizes.width,
      left: sizes.left,
      right: sizes.right,
      top: sizes.top + offset,
      width: sizes.height,
      x: sizes.x,
      y: sizes.y + offset,
    };

    switch (position) {
      case "TopLeft":
        return {
          top: offsetSizes.y - offsetY,
          left: offsetSizes.x,
          transform: `translateY(-100%) translateX(-100%) translateX(${offsetArrow}px)`,
        };
      case "TopCenter":
        return {
          top: offsetSizes.y - offsetY,
          left: offsetSizes.x + offsetSizes.width / 2,
          transform: "translateY(-100%) translateX(-50%)",
        };
      case "TopRight":
        return {
          top: offsetSizes.y - offsetY,
          left: offsetSizes.right,
          transform: `translateY(-100%) translateX(-${offsetArrow}px)`,
        };

      case "LeftCenter":
        return {
          top: offsetSizes.y + offsetSizes.height / 2,
          left: offsetSizes.x,
          transform: `translateX(-100%) translateX(-${offsetX}px) translateY(-50%)`,
        };

      case "RightCenter":
        return {
          top: offsetSizes.y + offsetSizes.height / 2,
          left: offsetSizes.right,
          transform: `translateX(${offsetX}px) translateY(-50%)`,
        };

      case "BottomLeft":
        return {
          top: offsetSizes.bottom + offsetY,
          left: offsetSizes.x + offsetSizes.width / 2,
          transform: `translateX(-100%) translateX(${offsetArrow}px)`,
        };
      case "BottomCenter":
        return {
          top: offsetSizes.bottom + offsetY,
          left: offsetSizes.x + offsetSizes.width / 2,
          transform: "translateX(-50%)",
        };
      case "BottomRight":
        return {
          top: offsetSizes.bottom + offsetY,
          left: offsetSizes.right,
          transform: `translateX(-${offsetArrow}px)`,
        };
    }
  }
};

export default calculatePosition;
