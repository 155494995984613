import { useQuery } from '@tanstack/react-query';
import { getUserMainAccount } from '../../services/api/mainAccounts';

const useMainAccount = (userId: string | number) => {
  const {
    isLoading: isAccountLoading,
    error: accountError,
    data: response,
  } = useQuery(['mainAccount', userId], async () => getUserMainAccount(userId));

  const { data: accountData } = response || {};

  return { accountData, accountError, isAccountLoading };
};

export default useMainAccount;
