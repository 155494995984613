import clsx from 'clsx';
import { FC } from 'react';
import { ReactComponent as OkaneIcon } from '../../assets/svg/icons/16/okane.svg';
import ISpinnerProps from './Spinner.interface';
import styles from './style.module.scss';

const Spinner: FC<ISpinnerProps> = ({
  className,
  wrapperClassName,
  height = 16,
}) => {
  return (
    <span className={clsx(styles.wrapper, wrapperClassName)}>
      <OkaneIcon
        className={clsx(styles.icon, className)}
        style={{ height: `${height}px` }}
      />
    </span>
  );
};

export default Spinner;
