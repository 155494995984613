import { useQuery } from '@tanstack/react-query';
import { getUserOrders } from '../../services/api/orders';

const useUserOrders = (userId: string | number) => {
  const {
    isLoading: isOrdersLoading,
    error: ordersError,
    data: response,
  } = useQuery(['orders', userId], async () => getUserOrders(userId));

  const { data: ordersData } = response || {};

  return { ordersData, ordersError, isOrdersLoading };
};

export default useUserOrders;
