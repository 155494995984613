import { AxiosRequestConfig } from 'axios';
import { IHydraBase, IHydraTop } from './types';
import axios from '../axios';

export interface IRedeem extends IHydraBase, IHydraTop {
  recipient: string;
  coupon: string;
  transaction: string;
  id: string;
}

export interface ICreateRedeemsParams {
  code: string;
}

export async function createRedeem(
  params: ICreateRedeemsParams,
  config?: AxiosRequestConfig<IRedeem>,
) {
  return axios.post<IRedeem>('/redeems', params, config);
}
