import { FC } from 'react';
import Popup from '../../components/Popup';
import IPopupProps from '../../components/Popup/Popup/Popup.interface';
import { GiveCard } from '../Cards';
import styles from './style.module.scss';

interface IGiveKarmaPopupProps extends Omit<IPopupProps, 'children'> {
  onSubmit: (value: any) => void;
}

const GiveKarmaPopup: FC<IGiveKarmaPopupProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Popup onClose={onClose} open={open} className={styles.override}>
      <div className={styles.container}>
        <h1>Подарить карму</h1>
        <GiveCard onSubmit={onSubmit} className={styles.card} />
      </div>
    </Popup>
  );
};

export default GiveKarmaPopup;
